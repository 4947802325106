/* @format */
/* index.css -- sitewide stylesheets */

/* global imports */
@import url("navbar.css");
@import url("displayDrinks.css");
@import url("spinner.css");
@import url("welcome.css");

/* font imports */
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

/* media queries */

/*for screens large enough to see drinkViz*/
@media (max-width: 900px) {
  html,
  .navbutton,
  .results {
    font-size: 16px;
  }
  #vizButton {
    display: none;
  }
  #glass {
    display: none;
  }
}
/* for mobile devices and smaller screens */
@media (min-width: 901px) {
  @import url("smallScreenResults.css");
  html {
    font-size: 16px;
  }
}

/* colors:
 *  original charcoal: #4c4c4c
 *  burnt orange: #a5693d
 *  dark-mode charcoal: #303030
 *  dark-mode egg-shell: #efeae5 
 *  complimentary blue: #100656
 */

/* global variables, mostly colors */

:root {
  /* main text/logo/nav color scheme */
  --main-bg-color: #fff;
  --form-bg-color: #fff;
  --form-text-color: #4c4c4c;
  --main-text-color: #4c4c4c;
  --main-accent-color: #a5693d;
  --navbar-bg-color: #4c4c4c;
  --navbar-txt-color: #efeae5;

  /* fonts */
  --primary-fontfam: "Roboto", sans-serif;
  --secondary-fontfam: sans-serif;

  /* drinkViz */
  --vizLabels: #444;
  --vizLines: #888;
  --vizCircleOutline: #999;
  --vizBg: #c8c8c820;

  /* drinkViz drink types */
  --vizStirred: #a5693d; /* drinkBase orange */
  --vizBubbly: #fcf5bf; /* eggshell */
  --vizShaken: #100656; /* dark blue */
  --vizDoubleShake: #240ccc; /* light blue */
  --vizFizz: #f4e381; /* custard */
  --vizSwizzle: #f27552; /* orange */
  --vizBuilt: #b5390c; /* red */
  --vizDefault: #bbb; /* grey */
  --vizHot: red;
}

/* grid layout */
.siteWrapper {
  display: grid;
  grid-template-colums: 1fr;
  grid-template-rows: auto 1fr;
}

.navbar {
  grid-row: 1 / 2;
}

.searchForms {
  position: relative;
  padding: 1em;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#results {
  /* only used when Viz enabled */
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  padding-top: 1em;
}
#justInfo {
  /* used when Viz disabled */
  padding-top: 1em;
  grid-column: 1 / 3;
}
#drinkViz {
  position: fixed;
  left: 60vh;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  padding-top: 4em;
  padding-right: 1em;
}

/* general styles */

body {
  font-family: var(--primary-fontfam), sans-serif;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-weight: normal;
  margin: 0;
}
h2 {
  padding: 0;
  margin: 0;
}
li {
  margin-bottom: 10px;
  list-style-type: none;
}
.active {
  border-bottom-style: solid;
  border-color: var(--main-accent-color);
  border-width: 0.3em;
}

footer {
  position: fixed;
  bottom: 0em;
  font-size: 75%;
  margin-top: 12px;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 2em;
  color: var(--main-accent-color);
  background-color: var(--main-bg-color);
  width: 100%;
}

#noResults {
  color: var(--main-accent-color);
  margin-left: 2em;
  font-size: 125%;
  font-weight: bold;
}

form input {
  background-color: var(--form-bg-color);
  color: var(--form-text-color);
  border-style: 1px solid;
  border-radius: 2px;
  font-size: 100%;
}

.searchbuttons {
  margin-top: 0.5em;
  margin-right: 0.5em;
  padding: 0.5em;
  color: var(--main-bg-color);
  background-color: var(--form-text-color);
  transition-duration: 0.2s;
}

.searchbuttons:hover {
  background-color: var(--main-accent-color);
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.indexSearch {
  grid-row: 2 / 3;
  top: 1em;
  padding-left: 1em;
  padding-top: 1em;
  display: grid;
  position: relative;
  top: 0;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
}
/*
#noviz {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-row: auto;
}

#yesviz {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-row: auto;
}
*/
#nv_results {
  grid-column: 1 / 3;
  padding-right: 1em;
  padding-top: 1em;
}

#yv_results {
  grid-column: 1 / 2;
}

#yv_viz {
  position: absolute;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}
#vizWrapper {
  position: fixed;
}

/* remove when put inline */
#legend {
  font-size: 12px;
}
