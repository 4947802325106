@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
/* @format */
/* navbar.css -- stylesheets for navigation bar */

.navbar {
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  top: 0px;
  background-color: var(--navbar-bg-color);
  color: var(--navbar-txt-color);
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  opacity: 0.95;
}

#smallLogoDiv {
  padding: 1px;
  margin-right: 2em;
  margin-left: 5px;
  margin-top: 3px;
  background-color: var(--navbar-txt-color);
}
#smallLogo {
  position: relative;
  vertical-align: bottom;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
}

#bigLogo {
  padding-left: 2em;
}

.navbutton {
  background-color: var(--navbar-header-color);
  color: var(--navbar-txt-color);
  border-style: none;
  vertical-align: top;
  margin-right: 2em;
}

.navbutton:hover {
  color: var(--main-accent-color);
  cursor: pointer;
}

/* @format */
/* displayDrinks.css -- displaying drink names and data on the screen*/

.drinkWrapper {
  display: grid;
  grid-template-columns: 6% 94%;
  grid-template-rows: auto;
  color: var(--main-accent-color);
  font-weight: bold;
  padding-bottom: 2em;
}
.glass {
}
#drinkName {
  font-size: 150%;
}
#ingreds {
  font-size: 80%;
  color: var(--main-text-color);
  font-weight: normal;
  padding-top: 0.5em;
  padding-left: 1em;
  font-style: italic;
}
.metrics {
  color: var(--main-text-color);
  font-weight: bold;
  font-size: 80%;
  padding-left: 1em;
}

.recipe {
  padding: 0;
  grid-column: 2 / 3;
  color: var(--main-text-color);
  font-weight: normal;
  margin: 0;
  margin-left: 0.5em;
  font-size: 90%;
  line-height: 0.5;
  cursor: pointer;
}
#build {
  line-height: 1;
}
.hideshow {
  margin: 0;
  text-indent: 3em;
  padding-top: -2em;
  font-size: 75%;
  font-style: normal;
}

.nameData {
  padding-right: 1em;
}

/* @format */
/* spinner.css -- stylesheets for loading spinner */

#flute1 {
  margin: 0;
  padding: 0;
  position: relative;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: clinkR;
          animation-name: clinkR;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
#flute2 {
  margin: 0;
  padding: 0;
  position: relative;
  top: 1em;
  bottom: 1em;
  left: 0em;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: clinkL;
          animation-name: clinkL;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes clinkR {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  15% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}
@keyframes clinkR {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  15% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}
@-webkit-keyframes clinkL {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  15% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
@keyframes clinkL {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  15% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
#loading {
  position: absolute;
  left: 7em;
}

/* @format */

#welcome {
  display: grid;
  grid-template-rows: auto auto;
}

#bigLogo {
  padding-top: 1em;
  padding-bottom: 1em;
  grid-row: 1 / 2;
}

#buttonWrapper {
}

#welcome button {
  font-family: var(--primary-fontfam);
  color: var(--main-text-color);
  height: 10em;
  width: 15em;
  font-size: 1em;
  margin: 0.5em;
  background-color: var(--main-bg-color);
  border-color: var(--main-accent-color);
  border-radius: 10px;
  transition-duration: 0.2s;
}

#welcomeIng {
  margin-left: 1em;
}

#welcome button:hover {
  color: var(--main-bg-color);
  background-color: var(--main-accent-color);
  cursor: pointer;
}

/* @format */
/* index.css -- sitewide stylesheets */

/* global imports */

/* font imports */

/* media queries */

/*for screens large enough to see drinkViz*/
@media (max-width: 900px) {
  html,
  .navbutton,
  .results {
    font-size: 16px;
  }
  #vizButton {
    display: none;
  }
  #glass {
    display: none;
  }
}
/* for mobile devices and smaller screens */
@media (min-width: 901px) {
  @import url("smallScreenResults.css");
  html {
    font-size: 16px;
  }
}

/* colors:
 *  original charcoal: #4c4c4c
 *  burnt orange: #a5693d
 *  dark-mode charcoal: #303030
 *  dark-mode egg-shell: #efeae5 
 *  complimentary blue: #100656
 */

/* global variables, mostly colors */

:root {
  /* main text/logo/nav color scheme */
  --main-bg-color: #fff;
  --form-bg-color: #fff;
  --form-text-color: #4c4c4c;
  --main-text-color: #4c4c4c;
  --main-accent-color: #a5693d;
  --navbar-bg-color: #4c4c4c;
  --navbar-txt-color: #efeae5;

  /* fonts */
  --primary-fontfam: "Roboto", sans-serif;
  --secondary-fontfam: sans-serif;

  /* drinkViz */
  --vizLabels: #444;
  --vizLines: #888;
  --vizCircleOutline: #999;
  --vizBg: #c8c8c820;

  /* drinkViz drink types */
  --vizStirred: #a5693d; /* drinkBase orange */
  --vizBubbly: #fcf5bf; /* eggshell */
  --vizShaken: #100656; /* dark blue */
  --vizDoubleShake: #240ccc; /* light blue */
  --vizFizz: #f4e381; /* custard */
  --vizSwizzle: #f27552; /* orange */
  --vizBuilt: #b5390c; /* red */
  --vizDefault: #bbb; /* grey */
  --vizHot: red;
}

/* grid layout */
.siteWrapper {
  display: grid;
  grid-template-colums: 1fr;
  grid-template-rows: auto 1fr;
}

.navbar {
  grid-row: 1 / 2;
}

.searchForms {
  position: relative;
  padding: 1em;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#results {
  /* only used when Viz enabled */
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  padding-top: 1em;
}
#justInfo {
  /* used when Viz disabled */
  padding-top: 1em;
  grid-column: 1 / 3;
}
#drinkViz {
  position: fixed;
  left: 60vh;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  padding-top: 4em;
  padding-right: 1em;
}

/* general styles */

body {
  font-family: "Roboto", sans-serif, sans-serif;
  font-family: var(--primary-fontfam), sans-serif;
  background-color: #fff;
  background-color: var(--main-bg-color);
  color: #4c4c4c;
  color: var(--main-text-color);
  font-weight: normal;
  margin: 0;
}
h2 {
  padding: 0;
  margin: 0;
}
li {
  margin-bottom: 10px;
  list-style-type: none;
}
.active {
  border-bottom-style: solid;
  border-color: #a5693d;
  border-color: var(--main-accent-color);
  border-width: 0.3em;
}

footer {
  position: fixed;
  bottom: 0em;
  font-size: 75%;
  margin-top: 12px;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 2em;
  color: #a5693d;
  color: var(--main-accent-color);
  background-color: #fff;
  background-color: var(--main-bg-color);
  width: 100%;
}

#noResults {
  color: #a5693d;
  color: var(--main-accent-color);
  margin-left: 2em;
  font-size: 125%;
  font-weight: bold;
}

form input {
  background-color: #fff;
  background-color: var(--form-bg-color);
  color: #4c4c4c;
  color: var(--form-text-color);
  border-style: 1px solid;
  border-radius: 2px;
  font-size: 100%;
}

.searchbuttons {
  margin-top: 0.5em;
  margin-right: 0.5em;
  padding: 0.5em;
  color: #fff;
  color: var(--main-bg-color);
  background-color: #4c4c4c;
  background-color: var(--form-text-color);
  transition-duration: 0.2s;
}

.searchbuttons:hover {
  background-color: #a5693d;
  background-color: var(--main-accent-color);
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.indexSearch {
  grid-row: 2 / 3;
  top: 1em;
  padding-left: 1em;
  padding-top: 1em;
  display: grid;
  position: relative;
  top: 0;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto auto;
}
/*
#noviz {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-row: auto;
}

#yesviz {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-row: auto;
}
*/
#nv_results {
  grid-column: 1 / 3;
  padding-right: 1em;
  padding-top: 1em;
}

#yv_results {
  grid-column: 1 / 2;
}

#yv_viz {
  position: absolute;
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}
#vizWrapper {
  position: fixed;
}

/* remove when put inline */
#legend {
  font-size: 12px;
}

